import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        "关注作者：": "Follow the author：",
        "红色": "Red",
        "酒红色": "Burgundy",
        "金发": "Blond",
        "银发": "Silver",
        "紫色": "Purple",
        "紫色（浅）": "Light Purple",
        "粉色": "Pink",
        "粉色（浅）": "Light Pink",
        "蓝色": "Blue",
        "蓝色（浅）": "Light Blue",
        "绿色": "Green",
        "黄色": "Yellow",
        "漂染（更鲜艳)": "Bleached (Brighter)",
        "正在处理...": "Processing...",
        "提交": "Submit",
        "保存": "Save",
        "请先选择一张照片。": "Please select a photo first.",
        "图片上传失败。": "Image upload failed.",
        "上传图片时发生错误。": "An error occurred while uploading the image.",
        "服务器未返回有效的提示ID。": "The server did not return a valid prompt ID.",
        "轮询队列状态时发生错误。": "An error occurred while polling the queue status.",
        "轮询结果时发生错误。": "An error occurred while polling the results.",
        "获取结果超时，请重试。": "Timeout while fetching results, please try again.",
        "预估10秒内完成": "Estimated completion within 10 seconds",
        "排队人数较多": "A large number of people in queue",
        "预估": "Estimated",
        "秒内开始": "seconds to start",
        "排队中": "Queuing",
        "appTitle": "Image Magician",
        "description": "Image Magician is a website that provides high-quality image processing services. Offering features like one-click enhancement, AI enhancement, and more AI features~",
        "获取结果中...": "Getting result...",
        "bottomDesc": "Magic Photo: One-click big chest, AI life drawing, and other AI image processing applications, technological innovation, creating miracles.",
        "联系我们": "Contact Us",
        improvement: "Improvement",
        oneClickBigBoob: "One Click Big Boob",
        superResolution: "Super Resolution",
        oneClickHairColor: "One Click Hair Color",
        styleTransfer: "Style Transfer",
        creation: "Creation",
        imageCreation: "Image Creation",
        video: "Video",
        videoGeneration: "Video Generation (Debugging)",
        探索AI艺术: "Explore AI Art",
        影像魔术师: "Magic Photo",
        请先登录: "Please log in first",
        请先充值才能使用本功能:"Please recharge before using this function",
        "魔法币不足, 限时大礼包优惠等你来": "Insufficient magic coins, limited-time gift package discounts are waiting for you",

        // 登陆页面
        "loginRegister": "Login/Register",
        "logout": "Logout",
        "email": "Email",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "verificationCode": "Verification Code",
        "sendVerificationCode": "Send Verification Code",
        "forgotPassword": "Forgot Password?",
        "noAccountRegister": "No account? Click to register",
        "backToLogin": "Back to Login",
        "loginRegisterButton": "Login/Register",
        "resetPassword": "Reset Password",
        "registerNewUser": "Register New User",
        "loading": "Loading...",
        "passwordMismatch": "Passwords do not match!",
        "emailRequired": "Email is required!",
        "passwordRequired": "Password is required!",
        "verificationCodeSent": "Verification code sent to your email!",
        "verificationCodeFailed": "Failed to send verification code, please try again later!",
        "passwordResetSuccess": "Password reset successfully, please login with the new password.",
        "passwordResetFailed": "Failed to reset password, please try again later!",
        "loginSuccess": "Login successful!",
        "loginFailed": "Login failed, please try again later!",
        "userNotExist": "User does not exist!",
        "passwordError": "Incorrect username or password!",

        landing: {
            title: "Magic Photo",
            subtitle: "Magic Photo: AI Image Editing Tool",
            feature1: "One-click Big Boob",
            feature2: "One-click Hair Dye",
            feature3: "One-click HD",
            feature4: "User-friendly",
            feature4Detail: "interface",
            feature5: "Integrated",
            feature5Detail: "AI intelligent algorithm"
        },
        "加入内测": "Join Beta Test"
    },
    ja: {
        "更高分辨率": "超高解像度画像の保存",
        "提交": "魔法を起動",
        "提交try": "魔法を起動",
        "提交1": "魔法を起動(1コイン<del>8コイン</del>)",
        "提交2": "魔法を起動(2コイン<del>8コイン</del>)",
        "提交4": "魔法を起動(4コイン<del>8コイン</del>)",
        "Video提交10": "ビデオ生成(10コイン)",
        "SD3提交10": "SD3による生成(10コイン)",
        "bottomDesc": "マジックフォト：ワンクリックでバストアップ、AIによる画像生成などのAI画像処理アプリ。技術革新で奇跡を創造。",
        "improvement": "改善",
        "oneClickBigBoob": "一発バストアップ",
        "superResolution": "超高解像度",
        "oneClickHairColor": "一発染髪",
        "styleTransfer": "スタイル変換",
        "creation": "創造",
        "imageCreation": "画像作成",
        "video": "ビデオ",
        "videoGeneration": "ビデオ生成（デバッグ中）",

        // 登陆页面
        "loginRegister": "ログイン/登録",
        "logout": "ログアウト",
        "email": "メールアドレス",
        "password": "パスワード",
        "confirmPassword": "パスワードを確認",
        "verificationCode": "認証コード",
        "sendVerificationCode": "メールに認証コードを送信",
        "forgotPassword": "パスワードを忘れましたか？",
        "noAccountRegister": "アカウントがないですか？登録はこちら",
        "backToLogin": "ログインに戻る",
        "loginRegisterButton": "ログイン/登録",
        "resetPassword": "パスワードをリセット",
        "registerNewUser": "新しいユーザーを登録",
        "loading": "読み込み中...",
        "passwordMismatch": "パスワードが一致しません！",
        "emailRequired": "メールアドレスを入力してください！",
        "passwordRequired": "メールアドレスとパスワードは必須項目です！",
        "verificationCodeSent": "認証コードがメールに送信されました！",
        "verificationCodeFailed": "認証コードの送信に失敗しました。後で再試行してください！",
        "passwordResetSuccess": "パスワードがリセットされました。新しいパスワードでログインしてください。",
        "passwordResetFailed": "パスワードのリセットに失敗しました。後で再試行してくださ��！",
        "loginSuccess": "ログインに成功しました！",
        "loginFailed": "ログインに失敗しました。後で再試行してください！",
        "userNotExist": "ユーザーが存在しません！",
        "passwordError": "ユーザー名またはパスワードが間違っています！",

        "landing": {
            "title": "マジックフォト",
            "subtitle": "マジックフォト：AI画像編集ツール",
            "feature1": "ワンクリックでバストアップ",
            "feature2": "ワンクリックでヘアカラー変更",
            "feature3": "ワンクリックで高解像度化",
            "feature4": "ユーザーフレンドリーな",
            "feature4Detail": "操作インターフェース",
            "feature5": "統合された",
            "feature5Detail": "AIインテリジェントアルゴリズム"
        },
        "加入内测": "ベータテストに参加"
    },
    
    // 中文
    zh: {
        "更高分辨率": "保存超清图像",
        "提交": "启动魔法",
        "提交try": "启动魔法",
        "提交1": "启动魔法(1币<del>8币</del>)",
        "提交2": "启动魔法(2币<del>8币</del>)",
        "提交4": "启动魔法(4币<del>8币</del>)",
        "Video提交10": "生成视频(10币)",
        "SD3提交10": "Gen by SD3(10币)",
        "bottomDesc": "影像魔术师：一键大胸、AI生图等AI图像处理应用，技术革新，创造奇迹。",
        improvement: "改进",
        oneClickBigBoob: "一键丰胸",
        superResolution: "超清放大",
        oneClickHairColor: "一键染发",
        styleTransfer: "换画风",
        creation: "创造",
        imageCreation: "美图创作",
        video: "视频",
        videoGeneration: "视频生成(调试中)",

        // 登陆页面
        "loginRegister": "登录/注册",
        "logout": "退出登录",
        "email": "邮箱",
        "password": "密码",
        "confirmPassword": "确认密码",
        "verificationCode": "验证码",
        "sendVerificationCode": "发送验证码到邮箱",
        "forgotPassword": "忘记密码?",
        "noAccountRegister": "没有账号？点击注册",
        "backToLogin": "返回登录",
        "loginRegisterButton": "登录/注册",
        "resetPassword": "重置密码",
        "registerNewUser": "注册新用户",
        "loading": "加载中...",
        "passwordMismatch": "两次输入的密码不一致！",
        "emailRequired": "请输入邮箱地址！",
        "passwordRequired": "邮箱和密码是必填项！",
        "verificationCodeSent": "验证码已发送到您的邮箱！",
        "verificationCodeFailed": "发送验证码失败，请稍后再试！",
        "passwordResetSuccess": "密码已重置，请使用新密码登录。",
        "passwordResetFailed": "重置密码失败，请稍后再试！",
        "loginSuccess": "登录成功！",
        "loginFailed": "登录失败，请稍后再试！",
        "userNotExist": "用户不存在！",
        "passwordError": "用户名或密码错误！",

        landing: {
            title: "影像魔术师",
            subtitle: "影像魔术师：AI图片编辑工具",
            feature1: "一键大胸",
            feature2: "一键染发",
            feature3: "一键变高清",
            feature4: "用户友好的",
            feature4Detail: "操作界面",
            feature5: "集成",
            feature5Detail: "AI智能算法"
        },
        "加入内测": "加入内测"
    }
}

const i18n = createI18n({
    // 设置默认语言
    // locale: process.env.VUE_APP_LANG === "en" ? "en" : 'zh',
    locale: 'zh',
    messages,
    legacy: false,
})

export default i18n
