<template>
  <div class="hero min-h-full rounded-l-xl bg-base-200">
    <div class="hero-content py-12">
      <div class="max-w-md">

        <h1 class='text-3xl text-center font-bold '>
          <img src="../../assets/logo.webp" class="w-12 inline-block mr-2 mask mask-circle" alt="logo" />{{ $t("影像魔术师")
          }}
        </h1>

        <div class="text-center mt-12">
          <img src="../../assets/Intro.webp" alt="Awesome" class="w-48 inline-block rounded-md" />
        </div>

        <h1 class="text-2xl mt-8 font-bold">{{ $t("landing.subtitle") }}</h1>
        <p class="py-2 mt-4">✓ <span class="font-semibold">{{ $t("landing.feature1") }}</span></p>
        <p class="py-2">✓ <span class="font-semibold">{{ $t("landing.feature2") }}</span></p>
        <p class="py-2">✓ <span class="font-semibold">{{ $t("landing.feature3") }}</span></p>
        <p class="py-2">✓ {{ $t("landing.feature4") }}<span class="font-semibold">{{ $t("landing.feature4Detail")
            }}</span></p>
        <p class="py-2 mb-4">✓ {{ $t("landing.feature5") }}<span class="font-semibold">{{ $t("landing.feature5Detail")
            }}</span></p>
      </div>
    </div>
  </div>
</template>
  
  <script>

  export default {
    components: {
    }
  }
  </script>
  