<template>
    <div>
        <button @click="saveCanvasState">保存画布状态</button>
        <button @click="loadCanvasState">加载画布状态</button>
        <canvas ref="canvas" :width="width" :height="height" style="border:1px solid black;"></canvas>
    </div>
</template>

<script setup>
import { onMounted, ref, markRaw } from 'vue';
import { fabric } from 'fabric';

const width = 800;
const height = 600;
const canvas = ref(null);

const canvasInstance = ref(null);

onMounted(() => {
    canvasInstance.value = new fabric.Canvas(canvas.value);
    // disable eslint
    // eslint-disable-next-line
    let imgPath = 'https://api.sora.moemiku.com/sora/api/view?filename=20240425_204708_LV5KI1VJFBG_00001_.png&subfolder=core\draw&type=output'
    // 示例：加载底图`@/assets/${imageName}`
    fabric.Image.fromURL("result.png", (img) => {
        img.set({
            left: 0,
            top: 0,
            selectable: false // 如果不需要移动底图，可以设置为不可选
        });
        // 应用毛玻璃滤镜
        img.filters.push(new fabric.Image.filters.Blur({
            blur: 0.8  // 毛玻璃的程度
        }));
        img.applyFilters();
        canvasInstance.value.add(img);
        canvasInstance.value.sendToBack(img);

        // 示例：加载其他图像
        loadSampleImage(imgPath, 100, 100);
    });
});

// 加载示例图像函数
function loadSampleImage(url, left, top) {
    fabric.Image.fromURL('iPhone.png', (phoneimg) => {
        phoneimg.set({
            left: 0,
            top: 0,
            selectable: false // 如果不需要移动底图，可以设置为不可选
        });


        fabric.Image.fromURL(url, (img) => {
            let padding = 4;
            const targetWidth = phoneimg.width - padding * 2;
            const targetHeight = phoneimg.height - padding * 2;
            // 计算目标尺寸与图像原始尺寸的宽高比
            const targetRatio = phoneimg.width / phoneimg.height;
            const imageRatio = img.width / img.height;
            let xOffset = 0;
            let yOffset = 0;
            let xClipOffset = 0;
            let yClipOffset = 0;
            let radius = 1.0;
            // 根据比较结果选择合适的缩放方法
            if (imageRatio >= targetRatio) {
                // 图像比目标更宽，按高度缩放
                img.scaleToHeight(targetHeight);
                radius = targetHeight / img.height;
                // 调整位置，水平居中
                xClipOffset = (img.getScaledWidth() - targetWidth) / 2;
            } else {
                // 图像比目标更窄，按宽度缩放
                img.scaleToWidth(targetWidth);
                radius = targetWidth / img.width;
                // 调整位置，垂直居中
                yClipOffset = (img.getScaledHeight() - targetHeight) / 2;
            }
            xOffset = -xClipOffset + padding;
            yOffset = -yClipOffset + padding;

            console.log(left)
            console.log(top)
            console.log(xOffset)
            console.log(yOffset)

            const borderRadius = 70 / radius; // 圆角半径

            // 创建带圆角的裁剪路径
            const clipPath = new fabric.Rect({
                left: -targetWidth / radius / 2.0,
                top: -targetHeight / radius / 2.0,
                width: targetWidth / radius,
                height: targetHeight / radius,
                rx: borderRadius, // x轴方向的圆角
                ry: borderRadius, // y轴方向的圆角
            });

            img.set({
                left: xOffset,
                top: yOffset,
                clipPath: clipPath, // 将圆角矩形设置为裁剪路径
                selectable: false
            });

            
            // 创建一个组合将图像和矩形组合在一起
            const group = new fabric.Group([img, phoneimg], {
                left: 0,
                top: 0,
                selectable: true,
                uniScaleTransform: true
            });
            group.scaleToWidth(200);
            canvasInstance.value.add(markRaw(group));
            // canvasInstance.value.add(markRaw(img));
        });
    }, {
    crossOrigin: 'anonymous',
    onError: function() {
        console.error('加载跨域图片失败');
    }
});
}

// 保存画布状态
function saveCanvasState() {
    localStorage.setItem('canvasState', JSON.stringify(canvasInstance.value.toJSON()));
    alert('画布状态已保存');
}

// 加载画布状态
function loadCanvasState() {
    const savedState = localStorage.getItem('canvasState');
    if (savedState) {
        canvasInstance.value.loadFromJSON(savedState, () => {
            canvasInstance.value.renderAll();
            alert('画布状态已加载');
        });
    }
}
</script>
<style>
.controls {
    display: inline-block;
}
</style>