<template>
  <div @click="fetchPayment">
    <button>特惠充值</button>
    <!-- 注入返回的HTML -->
    <div id="paymentHtml"></div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import axios from 'axios';

// 使用 defineProps 定义 props
const props = defineProps({
  productID: String,
  paymentPlatform: String
});

// 刷新未支付订单
const refreshOrder = async () => {
  try {
    const refreshOrdersUrl = process.env.VUE_APP_BASE_URL + '/pay/order/refresh';
    const response = await axios.post(refreshOrdersUrl, {}, {
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    });
    console.log('刷新订单成功:', response);
  } catch (error) {
    console.error('刷新订单失败:', error);
  }
};

let isPaying = false


// 定义点击事件的处理函数
const fetchPayment = async () => {
  if (isPaying) {
    return
  }
  isPaying = true
  try {
    // 假设这是你的支付接口URL
    const paymentUrl = process.env.VUE_APP_BASE_URL + '/pay';
    let curURL = window.location.href;
    // 去掉后边的参数只要path
    curURL = curURL.split('?')[0];
    const params = {
      // 这里添加你的支付参数
      productID: props.productID,
      returnURL: curURL,
      paymentPlatform: props.paymentPlatform
    };

    // 发起请求
    const response = await axios.post(paymentUrl, params, {
      // 在这里添加必要的请求头，例如API密钥
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    });

    // 获取响应中的HTML内容
    const htmlContent = response.data.result;

    // 获取paymentHtml
    const paymentHtmlElement = document.getElementById('paymentHtml');
    // 将HTML内容注入到模板中
    paymentHtmlElement.innerHTML = htmlContent;
    // 提交他下边的表单
    paymentHtmlElement.querySelector('form').submit();
    // // 提交第一个表单
    // window.document.forms[0].submit();
  } catch (error) {
    console.error('支付接口调用失败:', error);
    // 在这里处理错误情况，比如显示错误消息
  } finally {
    isPaying = false
  }
};
refreshOrder();
</script>