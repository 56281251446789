<template>
    <label for="my_modal_7" v-if="!user.isLogin" class="btn btn-primary">{{ $t('loginRegister') }}</label>
    <input type="checkbox" id="my_modal_7" class="modal-toggle" v-model="isModalOpen"/>
    <div v-if="user.isLogin" class="btn" @click="logout">{{ $t('logout') }}</div>
    <div class="min-h-screen bg-base-200 items-center modal">
        <div id="loginPopup" class="card mx-auto w-full max-w-5xl shadow-xl modal-box">
            <form method="dialog">
                <label for="my_modal_7" class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
            </form>
            <div class="grid md:grid-cols-2 grid-cols-1 bg-base-100 rounded-xl">
                <div class="landing-intro">
                    <LandingIntro />
                </div>
                <div class="py-24 px-10">
                    <h2 class="text-2xl font-semibold mb-2 text-center">{{ loginButtonText }}</h2>
                    <form @submit.prevent="submitForm">
                        <div class="mb-4">
                            <InputText v-model="loginObj.email" type="email" :labelTitle="$t('email')" />
                            <InputText v-model="loginObj.password" type="password" :labelTitle="$t('password')" />
                            <InputText v-model="confirmPassword" type="password" :labelTitle="$t('confirmPassword')" v-if="isRegistering" />
                            <div v-if="isForgotPassword">
                                <InputText id="code" v-model="verificationCode" type="text" :labelTitle="$t('verificationCode')" placeholder="请输入验证码" />
                                <button type="button" class="btn btn-secondary mt-2" @click="sendVerificationCode">{{ $t('sendVerificationCode') }}</button>
                            </div>
                        </div>
                        <div class="text-right text-primary" v-if="!isForgotPassword && !isRegistering">
                            <span class="cursor-pointer" @click="onForgotPassword">{{ $t('forgotPassword') }}</span>
                        </div>
                        <div class="flex items-center">
                            <p v-if="errorMessage" class="text-error">{{ errorMessage }}</p>
                            <p v-if="showSwitchToRegister" class="text-primary cursor-pointer ml-auto" @click="isRegistering = true; showSwitchToRegister = false">{{ $t('noAccountRegister') }}</p>
                        </div>
                        <div class="flex">
                            <button v-if="isRegistering || isForgotPassword" type="button" class="btn mt-2 mr-2" @click="onClickBackToLogin">{{ $t('backToLogin') }}</button>
                            <button type="submit" :class="['btn mt-2 btn-primary flex-grow']">
                                <span v-if="!loading">{{ loginButtonText }}</span>
                                <span v-else>{{ $t('loading') }}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <label class="modal-backdrop" for="my_modal_7">Close</label>
    </div>
</template>

<script setup>
import { ref, watch, inject, nextTick } from 'vue';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import LandingIntro from './LandingIntro.vue';
import InputText from '../../components/Input/InputText.vue';
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
const analytics = getAnalytics();

const { t } = useI18n();

// const INITIAL_LOGIN_OBJ = { email: 'wudijimao@qq.com', password: 'wdjm1hj' };
const INITIAL_LOGIN_OBJ = { email: '', password: '' };
const loading = ref(false);
const errorMessage = ref("");
const loginObj = ref(INITIAL_LOGIN_OBJ);
const confirmPassword = ref("");
const loginButtonText = ref(t('loginRegisterButton'));
const isRegistering = ref(false);
const isForgotPassword = ref(false);
const showSwitchToRegister = ref(false);
const isAutoSwitchToRegister = ref(true);
const verificationCode = ref("");
const isModalOpen = ref(false);

watch(isModalOpen, (newVal) => {
    if (!newVal) {
        isRegistering.value = false;
        isForgotPassword.value = false;
        showSwitchToRegister.value = false;
        isAutoSwitchToRegister.value = true;
        refreshLoginButtonText();
    }
});

let user = inject('user');

// 监听showLogin变化
watch(user, (newVal) => {
    if (newVal.showLogin) {
        user.showLogin = false;
        nextTick(() => {
            document.getElementById("my_modal_7").checked = true;
            logEvent(analytics, 'login_auto_show')
        });
    }
});

function refreshLoginButtonText() {
    if (isForgotPassword.value) {
        loginButtonText.value = t('resetPassword');
        return;
    }
    if (isRegistering.value) {
        loginButtonText.value = t('registerNewUser');
    } else if (isAutoSwitchToRegister.value) {
        loginButtonText.value = t('loginRegisterButton');
    } else {
        loginButtonText.value = t('login');
    }
}

watch(isRegistering, () => {
    refreshLoginButtonText();
});



// email变化的时候，变回登录
watch(loginObj, () => {
    isRegistering.value = false;
});

const sendVerificationCode = async () => {
    try {
        if (!loginObj.value.email.trim()) {
            errorMessage.value = t('emailRequired');
            return;
        }

        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/users/sendEmailCode', {
            email: loginObj.value.email
        });

        if (response.status === 200) {
            errorMessage.value = t('verificationCodeSent');
            // 定位到 code 输入框
            document.getElementById("code").focus();
        } else {
            errorMessage.value = t('verificationCodeFailed');
        }
    } catch (error) {
        console.error(error);
        errorMessage.value = t('verificationCodeFailed');
    }
};

const onClickBackToLogin = () => {
    isForgotPassword.value = false;
    isRegistering.value = false;
    isAutoSwitchToRegister.value = false;
    refreshLoginButtonText();
};

const onForgotPassword = () => {
    isForgotPassword.value = true;
    refreshLoginButtonText();
};

const loginSuccessed = () => {
    localStorage.removeItem('redeemed');
    user.isLogin = true;
    // 登录成功后的逻辑...
    console.log("登录成功！");
    // 重置表单数据
    loginObj.value = INITIAL_LOGIN_OBJ;
    loading.value = false;
    // 关闭登录弹窗
    document.getElementById("my_modal_7").checked = false;

    setUserId(analytics, loginObj.value.email);
    logEvent(analytics, 'login_success');
};


const submitForm = async () => {
    errorMessage.value = "";
    // 在尝试注册之前检查两次密码输入是否一致
    if (isRegistering.value && loginObj.value.password !== confirmPassword.value) {
        errorMessage.value = t('passwordMismatch');
        return;
    }
    if (!loginObj.value.email.trim() || !loginObj.value.password.trim()) {
        errorMessage.value = t('passwordRequired');
        return;
    }
    loading.value = true;
    if (isForgotPassword.value) {
        try {
            await axios.post(process.env.VUE_APP_BASE_URL + '/users/reset_password', {
                email: loginObj.value.email,
                verificationCode: verificationCode.value,
                newPassword: loginObj.value.password
            });
            errorMessage.value = t('passwordResetSuccess');
            isForgotPassword.value = false;
            loginButtonText.value = t('loginRegisterButton');
        } catch (error) {
            console.error(error);
            errorMessage.value = t('passwordResetFailed');
        }
        loading.value = false;
        return;
    }
    if (isRegistering.value) {
        // 尝试注册的逻辑...
        const isRegisterSuccess = await tryRegister();
        if (isRegisterSuccess) {
            // 注册成功后，重置表单数据
            loginSuccessed();
            logEvent(analytics, 'login_success');
        }
        loading.value = false;
        return;
    }


    // 尝试登录的逻辑...
    const loginResult = await tryLogin();
    if (loginResult === LOGIN_ERROR.success) {
        loginSuccessed();
        return;
    } else if (loginResult === LOGIN_ERROR.userNotExist) {
        // 如果用户不存在，则设置isRegistering为true，这样用户在下次提交表单时将包括确认密码的检查
        if (isAutoSwitchToRegister.value) {
            isRegistering.value = true;
        } else {
            showSwitchToRegister.value = true;
        }
    } else if (loginResult === LOGIN_ERROR.passwordError) {
        errorMessage.value = t('passwordError');
    } else {
        // 其他错误情况
        errorMessage.value = t('loginFailed');
    }
    loading.value = false;
};

const logout = () => {
    // 在这里添加退出登录的代码
    localStorage.removeItem('token');
    user.isLogin = false;
    // vue3 全局通知用户状态变化

}

// 登录错误枚举
const LOGIN_ERROR = {
    passwordError: t('passwordError'),
    userNotExist: t('userNotExist'),
    default: t('loginFailed'),
    success: t('loginSuccess'),
};

// 省略了tryLogin和tryRegister的伪代码
const tryLogin = async () => {
    console.log(loginObj.value);
    try {
        let sendHeader = {
            email: loginObj.value.email,
            password: loginObj.value.password
        };
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/users/login', {}, {
            headers: sendHeader
        });
        console.log(response);
        // 保存token到localStorage
        const token = response.headers['authorization'];
        localStorage.setItem('token', token);
        return LOGIN_ERROR.success;
    } catch (error) {
        if (error.response != undefined) {
            if (error.response.status === 401) {
                if (error.response.headers['x-user-error'] === 'user_not_found') {
                    errorMessage.value = "用户不存在！";
                    return LOGIN_ERROR.userNotExist;
                } else {
                    errorMessage.value = "用户名或密码错误！";
                return LOGIN_ERROR.passwordError;
                }
            }
        }
        console.error(error);
        errorMessage.value = "登录失败，请稍后再试！";
        return LOGIN_ERROR.default;
    }
};

const tryRegister = async () => {
    console.log(loginObj.value);
    try {
        let sendData = {
            nickname: loginObj.value.email,
            email: loginObj.value.email,
            password: loginObj.value.password
        };

        // 动态添加 referrerId
        const referrerId = localStorage.getItem('referrerId');
        if (referrerId) {
            sendData.referrerId = referrerId;
        }

        // 是否兑换过兑换码
        const isRedeemed = localStorage.getItem('redeemed');
        if (isRedeemed) {
            sendData.add_info = { isEarlyVIP: true };
        }
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/users/register', 
        sendData,
         {
        headers:sendData
            });
        console.log(response);
        if (response.status === 200 || response.status === 201) {
            const token = response.headers['authorization'];
            localStorage.setItem('token', token);
            return true;
        } else {
            errorMessage.value = response.data.message;
            return false;
        }
    } catch (error) {
        console.error(error);
        errorMessage.value = "注册失败，请稍后再试！";
        return false;
    }
};
</script>
<style>
/* 媒体查询，针对屏幕宽度小于768px的情况 */
@media (max-width: 768px) {
    .landing-intro {
        display: none; /* 隐藏介绍页面 */
    }
}
#loginPopup {
    margin: 0 auto;
    padding: 0;
}
</style>