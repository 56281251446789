<template>
  <div class="container">
    <div class="dropdown dropdown-hover">
      <div tabindex="0" role="button" class="btn m-1">
        <span class="color-circle" :style="{ backgroundColor: selectedColor.hex }">
        </span>
        {{ selectedColor.name }}
      </div>
      <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        <!-- 使用v-for动态生成发色选择项 -->
        <li v-for="(color, index) in colors" :key="index" class="menu-item">
          <a class="select-option" :data-value="color.value" @click="selectColor(color)">
            <span class="color-circle" :style="{ backgroundColor: color.hex, borderColor: 'white' }"></span>
            {{ color.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="form-control">
      <label class="label cursor-pointer">
        <span class="label-text">{{ $t("漂染（更鲜艳)") }}&nbsp;&nbsp;</span>
        <input type="checkbox" v-model="checked" class="checkbox checkbox-primary" />
      </label>
    </div>
  </div>
  <ImageProcessWithServer class="hair-colored" 
    functionName="hair"
    :params="{ color: selectedColor.value, force: checked }"
    :defaultImages="defaultImagePaths">
  </ImageProcessWithServer>
</template>

<script>
import { ref } from 'vue';

import ImageProcessWithServer from '../components/ImageProcessWithServer.vue';

export default {
  components: {
    ImageProcessWithServer,
  },
  data() {
    return {
      colors: [
        { name: this.$t('红色'), value: 'red', hex: '#FF0000' },
        { name: this.$t('酒红色'), value: 'lburgundy', hex: '#8B0000' },
        { name: this.$t('金发'), value: 'blonde', hex: '#FAF0BE' },
        { name: this.$t('银发'), value: 'silver', hex: '#C0C0C0' },
        { name: this.$t('紫色'), value: 'purple', hex: '#800080' },
        { name: this.$t('紫色（浅）'), value: 'light_purple', hex: '#E6E6FA' },
        { name: this.$t('粉色'), value: 'pink ', hex: '#FFC0CB' },
        { name: this.$t('粉色（浅）'), value: 'light_pink', hex: '#FFB6C1' },
        { name: this.$t('蓝色'), value: 'blue', hex: '#0000FF' },
        { name: this.$t('蓝色（浅）'), value: 'light_blue', hex: '#ADD8E6' },
        { name: this.$t('绿色'), value: 'green', hex: '#008000' },
        { name: this.$t('黄色'), value: 'yellow', hex: '#EEEE00' }
      ],
      selectedColor: { name: '', hex: '' },
      checked: true
    };
  },
  mounted() {
    this.selectedColor = this.colors[0];
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      // 关闭下拉菜单
      document.activeElement.blur()
      let dropdownContent = document.querySelector('.dropdown-content');
      dropdownContent.animate([
        { opacity: 1 },
        { opacity: 0 }
      ], {
        duration: 200
      });
      setTimeout(() => {
        dropdownContent.style.display = 'none';
        setTimeout(() => {
          dropdownContent.style.display = '';
        }, 100);
      }, 200);
    },
  },
  setup() {
    // 默认图片路径的示例定义
    const defaultImagePaths = ref({
      first: require('@/assets/hair/origin.png'), // 默认上传图片路径
      second: require('@/assets/hair/result.png'), // 默认处理后图片路径
    });

    return {
      defaultImagePaths
    };
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    gap: 20px;
  }

  .hair-colored {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 100px);
  }

  .color-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid white;
    /* 设置白色描边 */
  }
</style>
