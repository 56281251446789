<template>
    <div class="image-process">
        <!-- 图片上传 -->
        <input v-if="!isTextToImage" type="file" class="file-input file-input-bordered w-full max-w-xs"
            accept="image/png, image/jpeg, image/webp" />
        <div flex-direction="row">
            <button class="btn btn-primary" @click="submitPrompt" :disabled="loading">
                <span v-if="loading" v-html="processingText"></span>
                <template v-else>
                    <span v-if="user.isLogin" v-html="submitText"></span>
                    <span v-else v-html="guestSubmitText"></span>
                </template>
            </button>
            <button v-if="results?.image && isIOS" class="btn btn-secondary" @click="saveToMobileDevice">
                {{ saveToMobileDeviceText }}
            </button>
            <a v-if="results?.image && !isIOS" :href="results.image" download="magic.png">
                <button class="btn btn-accent">{{ saveText }}</button>
            </a>
            <button v-if="results?.image && isFreeTry" class="btn btn-secondary" @click="showPaymentDialog">{{ highResText }}</button>
        </div>
        <div v-if="loading" class="flex flex-col items-center">
            <div class="loading m-5" />
            <div>{{ loadingText }}</div>
            <!-- <img style="max-width: 320px; margin-top: 20px;" src="../assets/pay.png" /> -->
            <BaiduAD v-if="isFreeTry" />
        </div>
        <!-- 显示错误信息 -->
        <div v-if="error" class="error">{{ error }}</div>
        <div v-if="isTextToImage" class="results-section">
            <template v-if="results">
                <video v-if="results.video" :src="results.video" controls autoplay loop />
                <img :src="results.image" />
            </template>
            <img v-else :src="defaultImages.first" />
        </div>
        <!-- 图片对比滑块，使用传入的默认图片作为初始值 -->
        <div class="results-section">
        <ImgComparisonSlider v-if="!isTextToImage" class="results-section">
            <!-- eslint-disable -->
            <template v-if="results">
                <img slot="first" :src="results.upload">
                </img>
                <img slot="second" :src="results.image" />
            </template>
            <template v-else>
                <img slot="first" :src="defaultImages.first" :alt="defaultImages.firstAlt" />
                <img slot="second" :src="defaultImages.second" :alt="defaultImages.secondAlt" />
            </template>
            <!-- eslint-enable -->
        </ImgComparisonSlider>
        <h1 v-if="!isTextToImage" class="before-title text-lg font-bold">Before</h1>
        <h1 v-if="!isTextToImage" class="after-title text-lg font-bold">After</h1>
        </div>
    </div>
</template>

<script>
import { inject, ref, watch, onMounted } from 'vue';
import axios from 'axios';
import { ImgComparisonSlider } from '@img-comparison-slider/vue';
import BaiduAD from  './BaiduAD.vue'
import { useI18n } from 'vue-i18n';
import md5 from 'md5';
import cloneDeep from 'lodash/cloneDeep';
import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export default {
    components: {
        ImgComparisonSlider,
        BaiduAD
    },
    props: {
        functionName: {
            type: String,
            required: true
        },
        submitButtonText: {
            type: String,
            required: false,
            default: '提交1'
        },
        params: {
            type: Object,
            required: true
        },
        isTextToImage: {
            type: Boolean,
            required: false,
            default: false
        },
        canFreeTry: {
            type: Boolean,
            required: false,
            default: false
        },
        responseType: {
            type: String,
            required: false,
            default: 'json'
        },
        defaultImages: {
            type: Object,
            required: true,
            default: () => ({ first: 'first', second: 'second' })
        },
    },
    setup(props) {
        const { t } = useI18n({ useScope: 'global' })

        const baseurl = process.env.VUE_APP_BASE_URL + "/sora/core/"
        const sendUrl = baseurl + props.functionName;
        const getResultURL = baseurl + "result/";

        const results = ref(null);
        const loading = ref(false);
        const queuePosition = ref(-1); // 队列位置, 正在排队 0代表正在处理
        const error = ref('');
        const loadingText = ref(t('正在处理...'));

        const isIOS = ref(false);
        const isAndroid = ref(false);
        
        const isFreeTry = ref(false);

        console.log(props);

        const saveToMobileDevice = async () => {
            if (results.value?.image) {
                if (navigator.canShare) {
                    try {
                        const response = await fetch(results.value.image);
                        const blob = await response.blob();
                        const file = new File([blob], 'magic.png', { type: blob.type });
                        await navigator.share({
                            files: [file],
                            title: t('分享图片'),
                            text: t('查看我生成的图片！')
                        });
                    } catch (error) {
                        console.error('分享失败:', error);
                        alert(t('分享失败，请尝试其他方式保存图片。'));
                    }
                } else {
                    const response = await fetch(results.value.image);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'magic.png';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
            }
        };


        // 中文文本管理
        const processingText = t('正在处理...');
        const guestSubmitText = t('提交');
        const normalSubmitText = t(props.submitButtonText);
        const saveText = t('保存');
        const highResText = t('更高分辨率');

        const submitText = ref(normalSubmitText);
        
        // 保存到相册
        const saveToMobileDeviceText = t('保存到相册');
        const selectPhotoText = t('请先选择一张照片。');
        const uploadFailedText = t('图片上传失败。');
        const processingErrorText = t('上传图片时发生错误。');
        const invalidPromptIdText = t('服务器未返回有效的提示ID。');
        const queueStateErrorText = t('轮询队列状态时发生错误。');
        const resultPollingErrorText = t('轮询结果时发生错误。');
        const timeoutErrorText = t('获取结果超时，请重试。');
        const getingResultText = t('获取结果中...');

        // 排队文本拼接方法的国际化版本
        const formatQueueText = (position) => {
            if (position === 0) {
                return `${processingText} (${t('预估10秒内完成')})`;
            } else {
                const estimatedTime = position * 10;
                if (position >= 6) {
                    return `${t('排队人数较多')}(${position}) (${t('预估')} ${estimatedTime} ${t('秒内开始')})`;
                } else {
                    return `${t('排队中')}(${position})... (${t('预估')} ${estimatedTime} ${t('秒内开始')})`;
                }
            }
        };

        function genAuthHeader(urlString) {
            // 使用URL对象解析
            var url = new URL(urlString);
            // 获取路径
            var path = url.pathname;
            let appClient = "OfficalWeb"
            var appKey = "OfficalWeb996icu";
            let timestamp = new Date().getTime();
            let sign = md5(timestamp + appKey + path);
            return {
                'x-api-key': sign,
                'x-api-timestamp': timestamp,
                'x-api-client': appClient,
                'Authorization': localStorage.getItem('token')
            }
        }

        async function uploadImage(file) {
            const formData = new FormData();
            if (!props.isTextToImage) {
                // 不是文生图，传图
                formData.append('image', file);
            }
            // props.params 设置到 formData 中 分别作为key value
            for (const [key, value] of Object.entries(props.params)) {
                formData.append(key, value);
            }

            if (file) {
                // 创建本地URL
                results.value = {
                    upload: URL.createObjectURL(file),
                }
            }

            if (props.responseType === 'blob') {
                // 如果是二进制数据，直接返回
                return await axios.post(sendUrl, formData, {
                    headers: genAuthHeader(sendUrl),
                    responseType: 'blob'
                }).then(response => response.data);
            } else {
                const response = await axios.post(sendUrl, formData, {
                    headers: genAuthHeader(sendUrl)
                }); // 直接传递 formData，无需手动设置 headers
                console.log('Image uploaded successfully');
                return response.data;
            }
        }

        const redeem = inject('redeem');
        console.log(redeem);

        let user = inject('user');
        watch(() => cloneDeep(user), (newUser, oldUser) => {
            if (!oldUser.isLogin && newUser.isLogin) {
                error.value = '';
            }
            if (newUser.isLogin && props.canFreeTry) {
                // 可以免费试用的时候，如果魔法币小于1，则显示免费试用的文案
                if (user.coin < 1) {
                    submitText.value = t('提交try');
                    isFreeTry.value = true;
                } else {
                    submitText.value = normalSubmitText;
                    isFreeTry.value = false;
                }
            }
        },
            { deep: true });

        const showPaymentDialog = () => {
            setTimeout(() => {
                user.showChargeCoin = true;
                error.value = "购买魔术币以优先生成超清图片";
            }, 1);
        }

        onMounted(() => {
        // 检测设备类型
            const userAgent = navigator.userAgent.toLowerCase();
            isIOS.value = /ipad|iphone|ipod/.test(userAgent) && !window.MSStream;
            isAndroid.value = /android/.test(userAgent);
        });

        const submitPrompt = async () => {
            user.changCount = user.changCount + 1;
            // 使用次数，从localStorage中获取
            // let used = localStorage.getItem('used');
            // 是否兑换了
            // let redeemed = localStorage.getItem('redeemed');
            // if (redeemed || used < 1) {
            // 如果用户已经完成互动，执行所需功能
            submitPromptInternal(false);
            logEvent(analytics, 'submit_prompt_' + props.functionName);
            // } else {
            //     // 如果用户尚未完成互动，弹出提示并引导他们到Twitter
            //     redeem.showRedeemAlert = true;
            // }
        }
        const submitPromptInternal = async () => {
            queuePosition.value = -1;
            loadingText.value = processingText;
            loading.value = true;
            error.value = '';

            let file = null;

            if (!props.isTextToImage) {
                // 不是文生图就是图生图，这里传图
                // 1. 获取选择的文件
                const fileInput = document.querySelector('.file-input');
                file = fileInput.files[0];
                if (!file) {
                    error.value = selectPhotoText;
                    loading.value = false;
                    return;
                }
            }

            // 2. 上传图片
            try {
                let data = await uploadImage(file)
                if (data && data.taskId) {
                    user.changCount = user.changCount + 1;
                    await pollForResult(data.taskId);
                } else if (data) {
                    // sd3直接返回图片二进制数据，拼成二进制URL
                    const imgURL = URL.createObjectURL(new Blob([data], { type: 'image/png' }));
                    results.value = {
                        image: imgURL
                    };
                    loading.value = false;
                } else {
                    error.value = invalidPromptIdText;
                    loading.value = false;
                }
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    // 需要登录
                    user.showLogin = true;
                    error.value = t('请先登录');
                } else if (err.response && err.response.status === 402) {
                    if (err.response.data == "Please pay first") {
                        alert(t('请先充值才能使用本功能'));
                    } else {
                        alert(t('魔法币不足, 限时大礼包优惠等你来'));
                    }
                    user.showChargeCoin = true;
                } else {
                    error.value = processingErrorText;
                }
                console.error('上传图片失败:', err);
                loading.value = false;
            }
        };

        const pollForResult = async (promptId) => {
            let attempts = 0;
            const maxAttempts = 1200; // 最大尝试次数
            const interval = 2000; // 每次尝试的间隔时间（毫秒）

            while (attempts < maxAttempts) {
                try {
                    const response = await fetch(getResultURL + `${promptId}`);
                    const data = await response.json();

                    if (data.imgURL) {
                        let imgURL = data.imgURL
                        let videoURL = data.videoURL
                        if (props.isTextToImage) {
                            results.value = {
                                image: imgURL,
                                video: videoURL
                            };
                        } else {
                            results.value = {
                                upload: results.value.upload,
                                image: imgURL,
                                video: videoURL
                            };
                        }
                        loading.value = false;
                        // 保存使用次数到localStorage
                        let used = localStorage.getItem('used');
                        localStorage.setItem('used', used ? used + 1 : 1);
                        return;
                    } else if (data.queuePosition != undefined) {
                        queuePosition.value = data.queuePosition || 0;
                        // data.remainingTime
                        loadingText.value = formatQueueText(queuePosition.value);
                    } else {
                        loadingText.value = getingResultText
                    }
                } catch (error) {
                    console.error('Error:', error);
                    error.value = resultPollingErrorText;
                    break;
                }
                await new Promise(resolve => setTimeout(resolve, interval));
                attempts++;
            }

            if (attempts >= maxAttempts) {
                error.value = timeoutErrorText;
                loading.value = false;
            }
        };

        return {
            user,
            results,
            loading,
            error,
            loadingText,
            submitPrompt,
            processingText,
            guestSubmitText,
            submitText,
            highResText,
            saveText,
            saveToMobileDeviceText,
            selectPhotoText,
            uploadFailedText,
            processingErrorText,
            invalidPromptIdText,
            queueStateErrorText,
            resultPollingErrorText,
            timeoutErrorText,
            formatQueueText,
            saveToMobileDevice,
            isIOS,
            isAndroid,
            showPaymentDialog,
            isFreeTry
        }
    },
};
</script>

<style scoped>
.image-process {
    margin: 0;
}

button {
    margin: 10px;
}

.error {
    color: red;
}

.results-section img,
.results-section video {
    max-width: 100%;
    height: calc(100vh - 310px);
    display: block;
    margin: auto;
    object-fit: contain;
}
.results-section {
    position: relative; /* 添加相对定位以便子元素绝对定位 */
}

.before-title {
    position: absolute;
    top: 2%; /* 距离顶部2% */
    left: 2%; /* 距离左侧2% */
    text-shadow: 1px 1px 2px pink;
}

.after-title {
    position: absolute;
    top: 2%; /* 距离顶部2% */
    right: 2%; /* 距离右侧2% */
    text-shadow: 1px 1px 2px pink;
}
</style>
