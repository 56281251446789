<template>
  <div class="big-boob">
    <input type="range" min="0" max="3" v-model="size_value" class="range" step="1" style="max-width: 400px;" />
    <div class="w-full flex justify-between text-xs px-2 text-base-content" style="max-width: 400px;">
      <span>小</span>
      <span>中</span>
      <span>大</span>
      <span>巨(*)</span>
    </div>
    <ImageProcessWithServer
      class="big-boob"
      functionName="bigboob"
      canFreeTry=false
      :params="{ size: size_value }"
      :defaultImages="defaultImagePaths">
    </ImageProcessWithServer>
  </div>
</template>

<script>
import { ref } from 'vue';
import ImageProcessWithServer from '../components/ImageProcessWithServer.vue';
import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export default {
  components: {
    ImageProcessWithServer
  },
  methods: {
    
  },
  setup() {
    // 默认图片路径的示例定义
    const defaultImagePaths = ref({
      first: require('@/assets/big_breast/origin.webp'), // 默认上传图片路径
      second: require('@/assets/big_breast/result.webp'), // 默认处理后图片路径
      firstAlt: '一键丰胸前',
      secondAlt: '一键丰胸后'
    });

    const size_value = ref(2);

    logEvent(analytics, 'big_boob_page_viewed');

    return {
      defaultImagePaths,
      size_value
    };
  },
};
</script>

<style scoped>
.big-boob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: calc(100vh - 20px);
}

</style>
